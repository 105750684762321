import { version } from '../../../package.json';

import { ISecurableTree } from '../../services/UsersService';
import { paths } from '../../routes/Root/paths';

const root = '2a816185-af3d-46ae-9a94-65d3109b0408';

const securableTree: ISecurableTree[] = [
  {
    uid: root,
    parent: '20b3bb0a-6226-4cc7-9bcc-c9cb5c458f47',
    type: 'application',
    systemName: 'frontend',
    friendlyName: 'Фронтенд',
    hash: 'EiyK6A6QqZh80XEQhdBqsJU6SwGYBb+7kvrMwTVAQK0=',
    clientVersion: version
  },
  {
    uid: 'b39d78c0-ec94-4ddf-8bc6-ad9cb738e3a6',
    parent: root,
    type: 'page',
    systemName: paths.map.root,
    friendlyName: 'Карта'
  },
  {
    uid: '0dc5c189-1947-4c85-a699-a06ae64fbb40',
    parent: 'b39d78c0-ec94-4ddf-8bc6-ad9cb738e3a6',
    type: 'page',
    systemName: paths.map.office.root,
    friendlyName: 'Место: Просмотр'
  },
  {
    uid: '3bb26d38-127b-42b2-9b61-ade0b3b3e8a5',
    parent: '0dc5c189-1947-4c85-a699-a06ae64fbb40',
    type: 'input',
    systemName: 'officeSwitchInfrastructure',
    friendlyName: 'Место: переключатель "Инфрасруктура"'
  },
  {
    uid: '6790141d-9bff-419f-a99f-72a1b01811ba',
    parent: '0dc5c189-1947-4c85-a699-a06ae64fbb40',
    type: 'page',
    systemName: paths.map.office.create,
    friendlyName: 'Место: Добавить'
  },
  {
    uid: '101950a3-058d-43f8-9b41-e45990f8432b',
    parent: '0dc5c189-1947-4c85-a699-a06ae64fbb40',
    type: 'page',
    systemName: paths.map.office.edit(),
    friendlyName: 'Место: Изменить'
  },
  {
    uid: '320fa6c3-4c74-4715-bf0f-674a986734aa',
    parent: '0dc5c189-1947-4c85-a699-a06ae64fbb40',
    type: 'page',
    systemName: paths.map.office.plan.create,
    friendlyName: 'План: Добавить'
  },
  {
    uid: 'fde2e668-6d3c-4565-84b8-3b8645f1f37b',
    parent: '0dc5c189-1947-4c85-a699-a06ae64fbb40',
    type: 'page',
    systemName: paths.map.office.plan.edit(),
    friendlyName: 'План: Изменить'
  },
  {
    uid: 'f0843502-d986-441d-91fc-25043d077c33',
    parent: '0dc5c189-1947-4c85-a699-a06ae64fbb40',
    type: 'page',
    systemName: paths.map.office.users.root,
    friendlyName: 'Сотрудники: Список'
  },
  {
    uid: '8b41d800-963e-4eb4-bb8b-b1a9eeb94227',
    parent: 'f0843502-d986-441d-91fc-25043d077c33',
    type: 'page',
    systemName: paths.map.office.users.show(),
    friendlyName: 'Сотрудники: Просмотр'
  },
  {
    uid: '54ebc6ae-b806-45c6-8c9c-b905a97eb306',
    parent: 'f0843502-d986-441d-91fc-25043d077c33',
    type: 'page',
    systemName: paths.map.office.users.edit(),
    friendlyName: 'Сотрудники: Изменить'
  },
  {
    uid: '394162d8-ec3b-431e-860e-33d18d1fdfc5',
    parent: '0dc5c189-1947-4c85-a699-a06ae64fbb40',
    type: 'input',
    systemName: 'officeSwitchAreas',
    friendlyName: 'Место: переключатель "Зоны"'
  },
  {
    uid: '4467bc4e-1717-4258-a12a-a045874d24e4',
    parent: '0dc5c189-1947-4c85-a699-a06ae64fbb40',
    type: 'page',
    systemName: paths.map.office.areas.root,
    friendlyName: 'Зоны: Список'
  },
  {
    uid: '5f55affd-4184-41f8-bb67-0b87e3ff1b97',
    parent: '4467bc4e-1717-4258-a12a-a045874d24e4',
    type: 'page',
    systemName: paths.map.office.areas.show(),
    friendlyName: 'Зоны: Просмотр'
  },
  {
    uid: '9ea62306-bcfc-4d57-9168-5496f3045bac',
    parent: '4467bc4e-1717-4258-a12a-a045874d24e4',
    type: 'page',
    systemName: paths.map.office.areas.create,
    friendlyName: 'Зоны: Добавить'
  },
  {
    uid: '76382a0e-943d-4b6d-b3b3-d1cf25294de6',
    parent: '4467bc4e-1717-4258-a12a-a045874d24e4',
    type: 'page',
    systemName: paths.map.office.areas.edit(),
    friendlyName: 'Зоны: Изменить'
  },
  {
    uid: '96e901ae-d0af-403a-b14c-ce739243a349',
    parent: '0dc5c189-1947-4c85-a699-a06ae64fbb40',
    type: 'page',
    systemName: paths.map.office.infrastructure.root,
    friendlyName: 'Инфраструктура: Список'
  },
  {
    uid: '87b9337b-9b40-4ed7-a81a-391bf2401a80',
    parent: '96e901ae-d0af-403a-b14c-ce739243a349',
    type: 'page',
    systemName: paths.map.office.infrastructure.show(),
    friendlyName: 'Инфраструктура: Просмотр'
  },
  {
    uid: 'b83be04b-2636-4b98-82ca-8b4c3f6f46fc',
    parent: '96e901ae-d0af-403a-b14c-ce739243a349',
    type: 'page',
    systemName: paths.map.office.infrastructure.create,
    friendlyName: 'Инфраструктура: Добавление'
  },
  {
    uid: 'a60eb725-8193-42dc-bfee-134c88fe271b',
    parent: '96e901ae-d0af-403a-b14c-ce739243a349',
    type: 'page',
    systemName: paths.map.office.infrastructure.edit(),
    friendlyName: 'Инфраструктура: Изменение'
  },
  {
    uid: 'c57c5cce-eeae-4685-b7fb-fa2e0e055a72',
    parent: '0dc5c189-1947-4c85-a699-a06ae64fbb40',
    type: 'page',
    systemName: paths.map.office.cameras.root,
    friendlyName: 'Камеры: Список'
  },
  {
    uid: 'c603c819-f8a2-4c4a-ab40-975f1854794a',
    parent: 'c57c5cce-eeae-4685-b7fb-fa2e0e055a72',
    type: 'page',
    systemName: paths.map.office.cameras.show(),
    friendlyName: 'Камеры: Просмотр'
  },
  {
    uid: 'cc30b1d0-86ad-4e47-88af-f3108249af91',
    parent: 'c57c5cce-eeae-4685-b7fb-fa2e0e055a72',
    type: 'page',
    systemName: paths.map.office.cameras.create,
    friendlyName: 'Камеры: Добавление'
  },
  {
    uid: '16337146-ebba-4058-b9f5-5ab975c77af3',
    parent: 'c57c5cce-eeae-4685-b7fb-fa2e0e055a72',
    type: 'page',
    systemName: paths.map.office.cameras.edit(),
    friendlyName: 'Камеры: Изменение'
  },
  {
    uid: 'c94e1c86-0196-4b5a-8725-20b44f58c501',
    parent: root,
    type: 'page',
    systemName: paths.staff.root,
    friendlyName: 'Персонал'
  },
  {
    uid: 'e31fed38-39d5-4a0f-bc31-ab11359a7ba1',
    parent: 'c94e1c86-0196-4b5a-8725-20b44f58c501',
    type: 'page',
    systemName: paths.staff.users.root,
    friendlyName: 'Сотрудники'
  },
  {
    uid: 'c8d4164b-9c8e-4172-aa91-153d9fcc4221',
    parent: 'e31fed38-39d5-4a0f-bc31-ab11359a7ba1',
    type: 'page',
    systemName: paths.staff.users.create,
    friendlyName: 'Сотрудники: добавление'
  },
  {
    uid: '00b3e603-fc37-4488-8ca7-e669164e265f',
    parent: 'e31fed38-39d5-4a0f-bc31-ab11359a7ba1',
    type: 'page',
    systemName: paths.staff.users.edit(),
    friendlyName: 'Сотрудники: изменение'
  },
  {
    uid: 'e3b37b2c-1a43-4f9e-b37b-c7980dcfa390',
    parent: 'c94e1c86-0196-4b5a-8725-20b44f58c501',
    type: 'page',
    systemName: paths.staff.departaments.root,
    friendlyName: 'Структурные подразделения'
  },
  {
    uid: 'e173edef-34fe-4a68-91a8-656baeeaf64b',
    parent: 'e3b37b2c-1a43-4f9e-b37b-c7980dcfa390',
    type: 'page',
    systemName: paths.staff.departaments.create,
    friendlyName: 'Структурные подразделения: добавление'
  },
  {
    uid: '566a5e7e-01c8-4513-8796-5c1b7f9e19da',
    parent: 'e3b37b2c-1a43-4f9e-b37b-c7980dcfa390',
    type: 'page',
    systemName: paths.staff.departaments.edit(),
    friendlyName: 'Структурные подразделения: изменение'
  },
  {
    uid: 'c5aac0a7-e91a-4fd2-8a4a-cb39f38e5fcf',
    parent: 'e3b37b2c-1a43-4f9e-b37b-c7980dcfa390',
    type: 'page',
    systemName: paths.staff.departaments.copy(),
    friendlyName: 'Структурные подразделения: копирование'
  },
  {
    uid: '1a120c2c-b46c-4e57-ab22-3193a949e8c3',
    parent: root,
    type: 'page',
    systemName: paths.equipment.root,
    friendlyName: 'Оборудование'
  },
  {
    uid: 'a368a169-bc91-4027-807f-606e91d274cd',
    parent: '1a120c2c-b46c-4e57-ab22-3193a949e8c3',
    type: 'page',
    systemName: paths.equipment.beacons.root,
    friendlyName: 'Инфраструктура'
  },
  {
    uid: 'bc392241-9631-465b-b643-58e2cc3fca21',
    parent: '1a120c2c-b46c-4e57-ab22-3193a949e8c3',
    type: 'page',
    systemName: paths.equipment.cameras.root,
    friendlyName: 'Камеры'
  },
  {
    uid: '53ee0ba1-7d87-4483-a1b4-50716da8d853',
    parent: '1a120c2c-b46c-4e57-ab22-3193a949e8c3',
    type: 'page',
    systemName: paths.equipment.protections.root,
    friendlyName: 'Реестр СИЗ'
  },
  {
    uid: '6b745e07-dcfa-43f3-8d1b-1b88d912622f',
    parent: '53ee0ba1-7d87-4483-a1b4-50716da8d853',
    type: 'page',
    systemName: paths.equipment.protections.create,
    friendlyName: 'Реестр СИЗ: добавление'
  },
  {
    uid: 'ae4bb5be-c99a-461d-bcfd-4dfef044862f',
    parent: '53ee0ba1-7d87-4483-a1b4-50716da8d853',
    type: 'page',
    systemName: paths.equipment.protections.edit(),
    friendlyName: 'Реестр СИЗ: изменение'
  },
  {
    uid: '2cf56803-d908-4728-b5aa-f3fd230e485e',
    parent: '53ee0ba1-7d87-4483-a1b4-50716da8d853',
    type: 'page',
    systemName: paths.equipment.protections.copy(),
    friendlyName: 'Реестр СИЗ: копирование'
  },
  {
    uid: '75424e4e-0284-4ca8-afab-26d964501eeb',
    parent: '1a120c2c-b46c-4e57-ab22-3193a949e8c3',
    type: 'page',
    systemName: paths.equipment.devices.root,
    friendlyName: 'Носимые устройства'
  },
  {
    uid: '942d7082-1103-47ab-9635-3e101a988007',
    parent: '75424e4e-0284-4ca8-afab-26d964501eeb',
    type: 'page',
    systemName: paths.equipment.devices.create,
    friendlyName: 'Носимые устройства: добавление'
  },
  {
    uid: 'ebddaf01-bb52-4322-a5ea-dc00bd73cbfc',
    parent: '75424e4e-0284-4ca8-afab-26d964501eeb',
    type: 'page',
    systemName: paths.equipment.devices.edit(),
    friendlyName: 'Носимые устройства: изменение'
  },
  {
    uid: '1809bb70-5f3f-470f-8c02-98add6c87fd3',
    parent: '75424e4e-0284-4ca8-afab-26d964501eeb',
    type: 'page',
    systemName: paths.equipment.devices.copy(),
    friendlyName: 'Носимые устройства: копирование'
  },
  {
    uid: '3c200fbf-b6a7-4897-ba15-e971c56fb022',
    parent: root,
    type: 'page',
    systemName: paths.reports.root,
    friendlyName: 'Отчёты'
  },
  {
    uid: '22917d31-07c5-4687-9df3-de62146bd413',
    parent: root,
    type: 'page',
    systemName: paths.notifications,
    friendlyName: 'Уведомления'
  },
  {
    uid: '8d15c93a-e534-4f1c-80bd-837550c4395a',
    parent: root,
    type: 'page',
    systemName: paths.settings.root,
    friendlyName: 'Настройки'
  },
  {
    uid: '28bbda63-b762-4a35-8bb5-c329594d6934',
    parent: '8d15c93a-e534-4f1c-80bd-837550c4395a',
    type: 'page',
    systemName: paths.settings.notifications.root,
    friendlyName: 'Настройки: уведомления - просмотр'
  },
  {
    uid: 'c74e8cfe-0339-4ee3-a7bd-29bcee6ce429',
    parent: '8d15c93a-e534-4f1c-80bd-837550c4395a',
    type: 'page',
    systemName: paths.settings.notifications.create,
    friendlyName: 'Настройки: уведомления - добавление'
  },
  {
    uid: 'c2208671-1de6-4c81-a73b-10d5f754e31a',
    parent: '8d15c93a-e534-4f1c-80bd-837550c4395a',
    type: 'page',
    systemName: paths.settings.notifications.edit(),
    friendlyName: 'Настройки: уведомления - изменение'
  },
  {
    uid: '4d02eed1-f060-45a6-8fd7-dcf10a4a1233',
    parent: '8d15c93a-e534-4f1c-80bd-837550c4395a',
    type: 'page',
    systemName: paths.settings.protectionTypes.root,
    friendlyName: 'Настройки: типы СИЗ - просмотр'
  },
  {
    uid: 'fa8e519f-ad5a-4531-b055-4e1381142d65',
    parent: '8d15c93a-e534-4f1c-80bd-837550c4395a',
    type: 'page',
    systemName: paths.settings.protectionTypes.create,
    friendlyName: 'Настройки: типы СИЗ - добавление'
  },
  {
    uid: '4a33aed2-8134-4a79-b2d3-c56d506b06d5',
    parent: '8d15c93a-e534-4f1c-80bd-837550c4395a',
    type: 'page',
    systemName: paths.settings.protectionTypes.edit(),
    friendlyName: 'Настройки: типы СИЗ - изменение'
  }
];

export default { version, root, securableTree };
